<template>
    <div>
        <section class="login">
            <div class="inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">Artistas</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">Artistas</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="content_block padded rounded white">
                    <transition-group name="list" tag="div" class="artists_list grid_pad grid__motor grid__motor_col_5">
                        <article class="artist_item " v-for="artista in artistas" :key="artista.id">
                            <Artista :artista="artista"/>
                        </article>
                    </transition-group>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import Artista from '@/components/Artista'

export default {
    name: 'Artistas',
    components:{
        Artista
    },
    data: function(){
        return{
            artistas: []
        }
    },
    methods:{
        getArtistas: function(){
            axios
                .get(process.env.VUE_APP_URL+'artists', {
                    params:{

                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.artistas = rs.data;
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        }
    },
    created: function(){
        this.getArtistas();
    }
}
</script>