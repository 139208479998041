<template>
    <router-link :to="`/artistas/${artista.url}`" class="artist_item_img">
        <span class="artist_info">
            <h2 class="text_m text_500">{{artista.name}}</h2>
        </span>
        <figure>
                <img :src="artista.img" @error="artista.img = require('@/assets/images/img_void.jpg')"/>
        </figure>
    </router-link>
</template>

<script>
export default {
    name: 'Artista',
    props:{
        artista: Object
    },
    created: function(){
        if(!this.artista.img)
            this.artista.img = require('@/assets/images/img_void.jpg');
    }
}
</script>